<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>
        <b>Selecione abaixo o mês e o ano</b>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-form @submit="download">
            <validation-observer ref="observer">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-text-field :value="$store.state.contact.contact.city.name" label="Prefeitura" filled disabled
                        hide-details="auto" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider v-slot="{ errors }" vid="month">
                    <v-select v-model="form.month" :items="months" item-value="id" item-text="description" label="Mês"
                      filled hide-details="auto" :error-messages="errors" />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider v-slot="{ errors }" vid="year">
                    <v-select v-model="form.year" :items="years" item-value="id" item-text="description" label="Ano"
                      filled hide-details="auto" :error-messages="errors" />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row class="mt-4 px-4 pt-0 pb-1">
                <v-col class="text-right">
                  <v-btn color="success" :loading="loading" type="submit">
                    <v-icon left v-text="'mdi-download'" />
                    Download
                  </v-btn>
                </v-col>
              </v-row>
            </validation-observer>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-row class="px-4 pt-0 pb-1">
            <v-col class="text-right">
              <v-btn color="secondary" :loading="loadingContributionDeclaration" outlined
                @click="downloadContributionDeclaration">
                <v-icon left v-text="'mdi-download'" />
                Download declaração de contribuição de 2025
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import date from '@/utils/date'
import receiptsApi from '@/api/receipts'

export default {

  data: () => ({
    loading: false,
    loadingContributionDeclaration: false,
    months: date.months(),
    years: date.years(),
    form: {
      month: (new Date()).getMonth() + 1,
      year: (new Date()).getFullYear(),
    },
  }),

  methods: {
    async download(event) {
      try {
        event.preventDefault()
        this.loading = true

        const response = await receiptsApi.downloadByContact(this.form)

        const linkSource = `data:application/pdf;base64,${response.data.pdf}`
        const downloadLink = document.createElement('a')
        const fileName = `recibo_${this.form.month}${this.form.year}`

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      } catch (e) {
        if (e.response && e.response.status === 422) {
          return this.$refs.observer.setErrors(
            this.$apiError.fieldsValidation(e),
          )
        }

        const errorColor = e.response.status === 400 ? 'warning' : 'error'
        this.$snackbar.show({ color: errorColor, message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    async downloadContributionDeclaration() {
      try {
        this.loadingContributionDeclaration = true

        const response = await receiptsApi.downloadContributionDeclarationByContact()

        const linkSource = `data:application/pdf;base64,${response.data.pdf}`
        const downloadLink = document.createElement('a')
        const fileName = `declaracao_de_contribuicao_2025`

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      } catch (e) {
        const errorColor = e.response.status === 400 ? 'warning' : 'error'
        this.$snackbar.show({ color: errorColor, message: this.$apiError._(e) })
      } finally {
        this.loadingContributionDeclaration = false
      }
    }
  },

}
</script>
